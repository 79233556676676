import SubscriberController from './subscriber_controller';

export default class extends SubscriberController {
  get channel() {
    return 'HtmlUpdatesChannel';
  }

  received(data) {
    if (!this.match(data)) { return }

    this.element.outerHTML = data.html
  }

  match(data) {
    return (this.element.id === data.id)
  }
}
import ApplicationController from './application_controller';
import { globalEventBus, GlobalEvents } from '../utils/global_events';
import { FilePreviewer } from '../utils/file_previewer';

export default class extends ApplicationController {
  connect() {
    Object.values(GlobalEvents.fileUpload).forEach((event) => {
      globalEventBus.subscribe(event, this.handleFileUploadEvent.bind(this));
    })

    this.originalImageSrc = this.element.src
  }

  handleFileUploadEvent(event) {
    
    switch (event.type) {
      case GlobalEvents.fileUpload.uploaded:
        const file = event.file || event.files[0]
        const previewer = new FilePreviewer(file, this.element)
        
        if (file.type === 'application/pdf') {
          this.element.classList.add('h-full')
        }
        
        this.element.classList.remove('hidden');
        previewer.preview()
        break;
      case GlobalEvents.fileUpload.removed:
        this.element.src = this.originalImageSrc
        break;
    }
  }
}
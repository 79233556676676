import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['frontImagePreview', 'frontImageInput', 'backImagePreview', 'backImageInput']

  uploadImage(event) {
    if (this.isFrontImage(event.target)) {
      this.frontImageInputTarget.click()
    } else if (this.isBackImage(event.target)) {
      this.backImageInputTarget.click()
    }
  }

  previewImage(event) {
    if (this.isFrontImage(event.target)) {
      this.frontImagePreviewTarget.src = URL.createObjectURL(event.target.files[0])
      this.frontImagePreviewTarget.classList.remove('hidden');
    } else if (this.isBackImage(event.target)) {
      this.backImagePreviewTarget.src = URL.createObjectURL(event.target.files[0])
      this.backImagePreviewTarget.classList.remove('hidden');
    }
  }

  isFrontImage(target) {
    return target.dataset.imageUploadType == 'front'
  }

  isBackImage(target) {
    return target.dataset.imageUploadType == 'back'
  }
}
import ApplicationController from './application_controller';
import axios from '../utils/axios';

export default class extends ApplicationController {
  static targets = ['planSelectionCard', 'submitButton'];

  clickPlan(event) {
    var _this = this;

    this.planSelectionCardTargets.forEach(function(item) {
      _this.disablePlanCard(item);
    });

    var $el = event.currentTarget;
    
    if ($el.dataset.planSelectionTarget == 'planSelectionCard') {
      this.enablePlanCard($el);
    } else {
      var $parent = $el.closest('[data-plan-selection-target="planSelectionCard"]');
      this.enablePlanCard($parent);
    }
  }

  enablePlanCard(item) {
    item.querySelector('input[type="radio"]').checked = true;
    item.classList.add("Page--Plan_option-selected");
    this.submitButtonTarget.disabled = false;
    item.querySelector('.js-buttonSelectPlan').innerHTML = 'Plan Selected'
  }

  disablePlanCard(item) {
    item.querySelector('input[type="radio"]').checked = false;
    item.classList.remove("Page--Plan_option-selected");
    item.querySelector('.js-buttonSelectPlan').innerHTML = 'Select The Plan'
  }
}
import { Controller } from 'stimulus';

export default class extends Controller {
  static values = { urls: Object }

  pay(event) {
    const payButton = event.currentTarget

    payButton.classList.add('disabled')
    payButton.innerText = 'Redirecting...'

    Rails.ajax({
      type: 'POST',
      url: this.urlsValue.submit_payment_url,
      contentType: 'application/json',
      data: new URLSearchParams({}).toString(),
      success: (resp) => { window.location.href = resp.redirect_url },
      error: (resp) => {
        toastr.error(resp.error)

        payButton.classList.remove('disabled')
        payButton.innerText  = 'Pay'
      }
    })
  }

  cancel(_event) {
    if (confirm('This means that you will not be able to complete the process. Are you sure you want to Cancel?')) {
      Rails.ajax({
        type: 'POST',
        url: this.urlsValue.cancel_payment_url,
        contentType: 'application/json',
        data: new URLSearchParams({}).toString(),
        success: (resp) => { window.location.href = resp.redirect_url },
        error: (resp)   => { toastr.error(resp.error) }
      })
    }
  }
}
